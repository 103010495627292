import React, { Component } from "react"
import { string, number, any } from "prop-types"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import Img from "gatsby-image"

import "./style.scss"

class PortfolioCard extends Component {
  static propTypes = {
    logo: any,
    title: string,
    year: number,
    description: string,
    link: string,
  }

  render() {
    const { logo, title, year, description, link } = this.props
    return (
      <div className="portfolio-component">
        <div className="logo">
          <Img fluid={logo} />
        </div>
        <p className="title">{title}</p>
        <small>{year}</small>
        <p className="description">{description}</p>
        <Link to={link}>
          <Trans>Baca Selengkapnya</Trans>
        </Link>
      </div>
    )
  }
}

export default PortfolioCard
