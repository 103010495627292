import React, { Component } from "react"
import { string, oneOf, oneOfType, node, element, bool } from "prop-types"
import classnames from "classnames"

import "./style.scss"

class Card extends Component {
  static propTypes = {
    children: oneOfType([string, node, element]),
    paddingSize: oneOf(["small", "medium", "large"]),
    backgroundColor: oneOf(["primary", "secondary"]),
    hasBorderRadius: bool,
    hasBoxShadow: bool,
  }

  render() {
    const {
      children,
      paddingSize,
      backgroundColor,
      hasBorderRadius,
      hasBoxShadow,
      className,
      ...rest
    } = this.props

    const finalClassName = classnames(
      "card",
      `card--padding-${paddingSize}`,
      `card--background-${backgroundColor}`,
      {
        "card--has-border-radius": hasBorderRadius,
        "card--has-box-shadow": hasBoxShadow,
      },
      className
    )

    return (
      <div className={finalClassName} {...rest}>
        {children}
      </div>
    )
  }
}

export default Card
