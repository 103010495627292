import React, { Component } from "react"
import { string, bool, any } from "prop-types"
import { Link } from "gatsby-plugin-react-i18next"
import Img from "gatsby-image"

import Card from "../../components/Card"

import "./style.scss"

class ArticleCard extends Component {
  static propTypes = {
    isDeepArticle: bool,
    cover: any,
    title: string,
    date: string,
    description: string,
    link: string,
  }

  static defaultProps = {
    isDeepArticle: false,
  }

  render() {
    const { isDeepArticle, cover, title, date, description, link } = this.props

    return (
      <Link to={link} className="article-card">
        {isDeepArticle ? (
          <Card
            className="article-card__deep"
            backgroundColor="secondary"
            hasBoxShadow
            hasBorderRadius
          >
            <Img className="article-card__deep-cover" fluid={cover} />
            <div className="article-card__deep-content">
              <span>{date}</span>
              <h1>{title}</h1>
              {description ? <p>{description}</p> : null}
            </div>
          </Card>
        ) : (
          <Card
            className="article-card__common"
            backgroundColor="secondary"
            hasBoxShadow
            hasBorderRadius
          >
            <Img className="article-card__common-cover" fluid={cover} />
            <div className="article-card__common-content">
              <span>{date}</span>
              <h4>{title}</h4>
              {description ? <p>{description}</p> : null}
            </div>
          </Card>
        )}
      </Link>
    )
  }
}

export default ArticleCard
