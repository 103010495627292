import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Grid, ArrowRight } from "react-feather"
import { Link, Trans } from "gatsby-plugin-react-i18next"

import Layout from "../components/Layout"
import Container from "../components/Container"
import Card from "../components/Card"
import SEO from "../components/Seo"
import Button from "../components/Button"
import PortfolioCard from "../components/PortfolioCard"
import ArticleCard from "../components/ArticleCard"
import ContactCard from "../components/ContactCard"
import ClientSection from "../components/ClientSection"

import HomepageDotOrnament from "../images/homepage-dot-ornament.png"

import "../styles/pages/homepage.scss"

import ServicesData from "./services/services.json"

class IndexPage extends Component {
  render() {
    console.log("Develop by in.zakiy@gmail.com")
    const { data } = this.props
    const {
      site: { siteMetadata },
      insightData,
      portfolioData,
      homepageBannerBg,
    } = data

    const filteredDeepData = insightData.edges.filter(item => {
      return item.node.frontmatter.type === "Deep"
    })

    const filteredCommonData = insightData.edges.filter(item => {
      return item.node.frontmatter.type === "Common"
    })

    return (
      <Layout page="homepage">
        <SEO
          title="Home"
          description={`${siteMetadata.description}. Kompetensi dan Pengalaman kami siap mewujudkan pertumbuhan yang berkelanjutan Bisnis Anda.`}
          keywords={[
            siteMetadata.title,
            `consultant`,
            `advisory`,
            `properti`,
            `jakarta`,
          ]}
          image={
            homepageBannerBg ? homepageBannerBg.childImageSharp.resize : null
          }
        />
        <div className="homepage">
          <section className="homepage__banner">
            <Container>
              <h1>
                <Trans>Homepage Title</Trans>
              </h1>
              <div className="homepage__banner-separator mb-32 mt-48"></div>
              <p>
                <Trans>Homepage Description</Trans>
              </p>

              <div className="homepage__banner-button mt-64">
                <Button
                  tag="a"
                  href="#about-section"
                  size="large"
                  variant="secondary"
                >
                  <Trans>Pelajari lebih lanjut</Trans>
                </Button>
                <Button isGatsbyLink to="/services" size="large">
                  <Trans>Tingkatkan bisnis sekarang</Trans>
                </Button>
              </div>
              <Img
                className="homepage__banner-bg"
                fluid={homepageBannerBg.childImageSharp.fluid}
              />
            </Container>
          </section>

          <section id="about-section" className="homepage__about">
            <Container className="homepage__about-container">
              <img
                src={HomepageDotOrnament}
                className="homepage__about-ornament"
                alt="ornament"
              />
              <h2>
                <Trans>Homepage About Title</Trans>
              </h2>
              <p>
                <Trans>Homepage About Description</Trans>
              </p>
              <Link to="/about">
                <Trans>Tentang Provalindo</Trans>
                <ArrowRight className="ml-8" />
              </Link>
            </Container>
          </section>

          <ClientSection />

          <section className="homepage__portfolio">
            <Container className="homepage__portfolio-container">
              <h2>
                <Trans>Portfolio</Trans>
              </h2>
              <div className="homepage__portfolio-tabs">
                <div className="homepage__portfolio-tabs-content">
                  {portfolioData.edges.map(item => {
                    return (
                      <PortfolioCard
                        key={item.node.id}
                        logo={item.node.frontmatter.cover.childImageSharp.fluid}
                        title={item.node.frontmatter.title}
                        year={item.node.frontmatter.year}
                        description={item.node.frontmatter.description}
                        link={item.node.fields.slug}
                      />
                    )
                  })}
                </div>
              </div>
              <Link to="/portfolio" className="mt-32">
                <Grid className="mr-8" />
                <Trans>Lihat Semua Portfolio</Trans>
              </Link>
            </Container>
          </section>

          <section className="homepage__top-service">
            <Container className="homepage__top-service-container">
              <h2>
                <Trans>Top Services</Trans>
              </h2>
              <div className="homepage__top-service-list">
                {ServicesData.advisory.map((item, index) => {
                  return item.isTopService ? (
                    <Card
                      key={index}
                      className="mr-20"
                      hasBoxShadow
                      hasBorderRadius
                      paddingSize="large"
                      backgroundColor="secondary"
                    >
                      <h3>{item.name}</h3>
                      <p>
                        <Trans>{item.desc}</Trans>
                      </p>
                    </Card>
                  ) : null
                })}
                {ServicesData.consultant.map((item, index) => {
                  return item.isTopService ? (
                    <Card
                      key={index}
                      className="mr-20"
                      hasBoxShadow
                      hasBorderRadius
                      paddingSize="large"
                      backgroundColor="secondary"
                    >
                      <h3>{item.name}</h3>
                      <p>
                        <Trans>{item.desc}</Trans>
                      </p>
                    </Card>
                  ) : null
                })}
                {ServicesData.business.map((item, index) => {
                  return item.isTopService ? (
                    <Card
                      key={index}
                      className="mr-20"
                      hasBoxShadow
                      hasBorderRadius
                      paddingSize="large"
                      backgroundColor="secondary"
                    >
                      <h3>{item.name}</h3>
                      <p>
                        <Trans>{item.desc}</Trans>
                      </p>
                    </Card>
                  ) : null
                })}
              </div>
              <Link to="/services" className="mt-32" style={{ color: "white" }}>
                <Grid className="mr-8" />
                <Trans>Lihat Semua Services</Trans>
              </Link>
            </Container>
          </section>

          <section className="homepage__article">
            <Container className="homepage__article-container">
              <div className="homepage__article-subtitle">INSIGHT</div>
              <h2>
                <Trans>Insight Description</Trans>
              </h2>
              <div className="homepage__article-list">
                <div className="homepage__article-list-deep">
                  {filteredDeepData.slice(0, 1).map(item => {
                    return (
                      <ArticleCard
                        key={item.node.id}
                        link={item.node.fields.slug}
                        date={item.node.frontmatter.date}
                        title={item.node.frontmatter.title}
                        cover={
                          item.node.frontmatter.cover.childImageSharp.fluid
                        }
                        isDeepArticle
                      />
                    )
                  })}
                </div>
                <div className="homepage__article-list-common">
                  {filteredCommonData.slice(0, 2).map(item => {
                    return (
                      <ArticleCard
                        key={item.node.id}
                        link={item.node.fields.slug}
                        date={item.node.frontmatter.date}
                        title={item.node.frontmatter.title}
                        description={item.node.excerpt}
                        cover={
                          item.node.frontmatter.cover.childImageSharp.fluid
                        }
                      />
                    )
                  })}
                </div>
              </div>
              <Link to="/insight" className="mt-32">
                <Grid className="mr-8" />
                <Trans>Lihat Semua Insight</Trans>
              </Link>
            </Container>
          </section>

          <section className="homepage__contact">
            <Container>
              <ContactCard
                title="Contact Title"
                subtitle="Contact Description"
              />
            </Container>
          </section>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const homePageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    homepageBannerBg: file(
      relativePath: { eq: "images/homepage-banner-bg.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
        resize(width: 1000) {
          src
          height
          width
        }
      }
    }
    portfolioData: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "client-page" }
          isTop: { eq: true }
          isShow: { eq: true }
        }
      }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            year
            type
            isTop
            isShow
          }
          fields {
            slug
          }
        }
      }
    }
    insightData: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "article-page" }
          isShow: { eq: true }
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            date(formatString: "DD MMMM, YYYY")
            type
            isTop
            isShow
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
