import React, { Component } from "react"
import { Trans } from "gatsby-plugin-react-i18next"

import Container from "../Container"

import clientSection from "./clientSection.json"

import "./style.scss"

class ClientSection extends Component {
  render() {
    return (
      <section className="client">
        <Container className="client__container">
          <h2>
            <Trans>Client</Trans>
          </h2>
          <p>
            <Trans>Client Page Description</Trans>
          </p>
          {/* <div className="client__count">
            <div className="client__count-card">
              <span>35</span>
              <div className="client__count-card--separator"></div>
              <p>Perusahan Swasta</p>
            </div>
            <div className="client__count-card">
              <span>8</span>
              <div className="client__count-card--separator"></div>
              <p>Organisasi Pemerintah</p>
            </div>
            <div className="client__count-card">
              <span>3</span>
              <div className="client__count-card--separator"></div>
              <p>Badan Usaha Milik Negara</p>
            </div>
          </div> */}
          <div className="client__logo">
            {clientSection.data.map(item => {
              return (
                <div key={item.id} className="client__logo-wrapper">
                  <img
                    src={require(`../../images/${item.url}.png`)}
                    alt={item.alt}
                  />
                </div>
              )
            })}
          </div>
        </Container>
      </section>
    )
  }
}

export default ClientSection
